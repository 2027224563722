import Home from './home.jsx'
import './App.css';


function App() {
  return (
    <div>
      <Home />
    </div>
  );
}

export default App;
